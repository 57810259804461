.about-melancia {
  width: 60%;
  justify-self: center;
  margin-top: 2em;
  white-space: pre-line;
}
.about-logo {
  margin-top: 2em;
  width: 50%;
  box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
}

@media (max-width: 980px) {
  .white-box {
    width: 90%;
  }
  .about-logo {
    width: 90%;
  }
}
