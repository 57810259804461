.language-icon {
  width: 96px;
  display: flex;
  color: var(--white);
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
}

.country-flag {
  height: 24px;
}

@media (max-width: 600px) {
  .country-flag {
    height: 18px;
  }
}
