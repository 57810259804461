.person {
  min-width: 300px;
}

.person > img {
  border-radius: 100%;
  height: 176px;
  width: 176px;
}

.person > p {
  color: var(--white);
  font-size: 24px;
}

@media (max-width: 800px) {
  .person {
    min-width: 0px;
    max-width: 120px;
  }
  .person > img {
    height: 96px;
    width: 96px;
  }
  .person > p {
    font-size: 20px;
    text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.3);
  }
}
