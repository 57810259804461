.more-details-button {
  width: min-content;
  height: min-content;
  padding: 1em;
  text-align: center;
  align-content: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.more-details-button-white {
  color: var(--white);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
}

.more-details-button-black {
  color: var(--black);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
}
