:root {
  --banner-height: 50em;
}

.melancia-banner {
  max-height: var(--banner-height);
  min-height: var(--banner-height);
  width: 100%;
  background-color: #323232;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.melancia-banner-video {
  min-height: var(--banner-height);
  position: relative;
  display: flex;
  flex: 1;
  z-index: 0;
  height: 100%;
}

.melancia-banner-logo {
  position: absolute;
  width: 700px;
}

@media (max-width: 850px) {
  .melancia-banner-logo {
    width: 400px;
  }
}

@media (max-width: 400px) {
  .melancia-banner-logo {
    width: 300px;
  }
}

.melancia-banner-video-overlay {
  content: "";
  background-color: rgba(111, 39, 39, 0.5);
  position: absolute;
  width: 100%;
  height: var(--banner-height);
}
