.projects-container {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
  margin-bottom: 5em;
}

.projects-container > div {
  margin-bottom: 16px;
}
.projects-container > div + div {
  margin-left: 16px;
}

.people-container {
  margin-top: 5em;
  margin-bottom: 3em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 960px) {
  .projects-container > div {
    width: 25em;
    height: 20em;
  }
  .projects-container > div + div {
    margin-top: 1em;
    margin-left: 0px;
  }
}
