:root {
  --watermelon: #ff5858;
  --watermelon-darker: #f25555;
  --watermelon-even-darker: #c84646;
  --black: #161616;
  --white: #f6f6f6;
}

.App {
  text-align: center;
  width: 100%;
}

.App a {
  text-decoration: none;
}

@font-face {
  font-family: Montserrat;
  src: url("assets/fonts/Montserrat.ttf");
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--watermelon-darker);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(242, 85, 85, 1)),
    url("assets/Seeds50%.png");
  background-repeat: repeat;
  background-position: center;
  background-size: 20em;
  /* width: 100%; */
}

h1 {
  color: var(--white);
  font-family: Montserrat;
  font-weight: 800;
  font-size: 3em;
  text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
}

h2 {
  color: var(--white);
  font-family: Montserrat;
  font-weight: 600;
  font-size: 2em;
  text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
}

h3 {
  color: var(--white);
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1.5em;
  text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
}

.white-box {
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--black);
  font-size: 22px;
  font-weight: 500;
  padding: 1em;
  border-radius: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
