.outer-footer {
  min-height: 320px;
  background-color: #f0ffc0;
  display: flex;
  justify-content: end;
  flex-direction: column-reverse;
  margin-top: 128px;
}
.inner-footer {
  max-height: 300px;
  flex: 1;
  display: flex;
  background-color: #4dab0d;
  background-image: url("../../assets/melonshell.png");
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inner-footer > h3 {
  margin-top: 64px;
}
.inner-footer > p {
  color: #3a800a;
}
.social {
  margin-top: 16px;
  flex: 1;
  display: flex;
}
.social > img {
  height: 48px;
  width: 48px;
  cursor: pointer;
}
.social > img + img {
  margin-left: 32px;
}
