.about-founder {
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--black);
  font-size: 22px;
  font-weight: 500;
  width: 60%;
  display: flex;
  padding: 2em;
  border-radius: 8px;
  margin-bottom: 2em;
  margin-top: 2em;
}
.about-founder h2 {
  color: var(--black);
}
.founder-left {
  justify-self: start;
  margin-left: 7em;
}
.founder-right {
  justify-self: end;
  margin-right: 7em;
  flex-direction: row-reverse;
}
.founder-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: justify;
  text-wrap: stable;
  white-space: pre-line;
}
.founder-left > .founder-info {
  margin-left: 2em;
}
.founder-right > .founder-info {
  margin-right: 2em;
}

@media (max-width: 980px) {
  .about-founder {
    width: 90%;
    flex-direction: column;
    padding: 1em;
    justify-items: center;
  }
  .about-founder .person > img {
    width: 256px;
    height: 256px;
  }
  .about-founder .person {
    justify-content: center;
    max-width: 256px;
    width: 256px;
    height: 256px;
  }
  .founder-right {
    justify-self: center;
    margin-right: 0em;
  }
  .founder-left {
    justify-self: center;
    margin-left: 0em;
  }
  .founder-left > .founder-info {
    margin-left: 0em;
  }
  .founder-right > .founder-info {
    margin-right: 0em;
  }
}

@media (max-width: 600px) {
  .about-founder {
    align-items: center;
    margin-left: 0em;
  }
}
