.header {
  min-height: 5em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
}
.header-contact-links {
  display: flex;
  justify-content: space-around;
  width: 80%;
}

.header-contact-links > a {
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

.header-logo-without-text {
  display: inline;
}

.header-logo-with-text {
  display: none;
}

@media (max-width: 600px) {
  .header {
    min-height: 4em;
    justify-content: end;
    text-align: center;
  }
  .header-contact-links {
    display: none;
  }
  .header-logo-without-text {
    display: none;
  }
  .header-logo-with-text {
    display: inline;
    position: absolute;
    width: 100%;
    /* flex-grow: 2; */
  }
}
