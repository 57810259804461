.project-tile {
  width: 30em;
  height: 25em;
}

.project-details-tile {
  background-color: rgba(0, 0, 0, 0.6);
  width: 30em;
  height: 25em;
  z-index: 2;
  position: absolute;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-content: center;
}

.project-details-tile > h3 {
  font-size: 2.5em;
}

.project-tile > img {
  object-fit: cover;
}

.project-tile-subtitle {
  color: var(--white);
  font-size: 1.6em;
}

@media (max-width: 960px) {
  .project-details-tile {
    width: 25em;
    height: 20em;
  }
}
