.project-details-tile-container {
  flex: 1;
  display: flex;
  margin: 2em 2em 2em 2em;
  align-items: center;
  flex-direction: column;
}
.project-details-corpus {
  flex: 1;
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  margin-bottom: 1em;
}
.project-description {
  color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: start;
}
.project-description h1 {
  color: var(--black);
  text-shadow: none;
  margin-left: 1em;
}
.project-description span {
  color: var(--black);
  flex: 1;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  text-align: justify;
  text-wrap: stable;
}
.project-details-tile-container .project-tile {
  flex-shrink: 0;
  align-self: center;
}

@media (max-width: 980px) {
  .project-details-tile-container {
    width: 90%;
    margin: 6px 0 0 0;
    justify-self: center;
  }
  .project-details-tile-container .project-tile {
    height: 15em;
    width: 20em;
  }
  .project-details-corpus {
    justify-content: center;
    flex-direction: column;
  }
}
